import React, { useState, useMemo, useEffect, useContext } from 'react'
import { styled } from 'linaria/react'

import CartProvider from '@jetshop/core/components/Query/CartProvider'
import cartQuery from '../Cart/CartQuery.gql'
import t from '@jetshop/intl'

import {
   HELLO_RETAIL_SETTINGS,
   READ_MORE,
} from '../CustomElements/elementTypes'
import RenderCustomElements from '../CustomElements/RenderCustomElements'
import getParentOrCanonicalRoute from '@jetshop/ui/Breadcrumbs/getParentsOrCanonicalRoute'
import { useSiteSettingsQuery } from '../../hooks/useSiteSettings'
import loadable from '@loadable/component'
import LoadingPage from '../LoadingPage'
import HelloRetailCarousel from '../HelloRetail/Carousel'
import * as HelloRetailConst from '../HelloRetail/Constants'
import {
   generateHelloRetailId,
   getCategoryHierarchies,
   getHelloRetailProducts,
   getHelloRetailTrackingId,
   HelloRetailHelper,
   retrieveOrCreateCookie,
} from '../HelloRetail/HelloRetailHelper'
import { SiteContext } from '../Global/SiteContext'
import { UseHelloRetail } from '../HelloRetail/UseHelloRetail'
import { ACTIVATE_ACCOUNT_PAGE_ID, BRAND_PAGE_ID } from '../Core/Util/Constants'
import { HelloRetailToJetshopCarousel } from '../HelloRetail/HelloRetailToJetshop'

// const Container = styled.div`
//    .slider {
//       height: 1rem;
//    }

//    .product-grid-wrapper {
//       background-color: #e3e3e3;
//       border-top-left-radius: 32px 32px;
//    }
// `

// const Wrapper = styled.div`
//    background: ${theme.colors.white};,
// `

const HelloRetailWrapper = styled.div`
   > div:nth-child(odd) {
      background-color: #d4d4d4;
   }
`

const LoadableBrandsPage = loadable(() => import('../Pages/Brands/Brands'), {
   fallback: <LoadingPage />,
})

const LoadableBrandPage = loadable(
   () => import('../Pages/Brands/BrandSingle'),
   {
      fallback: <LoadingPage />,
   }
)

const LoadableCategoryStandardPage = loadable(
   () => import('../Pages/CategoryStandard/CategoryStandard'),
   {
      fallback: <LoadingPage />,
   }
)

const LoadableNewsletterRegistrationPage = loadable(
   () => import('../Pages/Newsletter/NewsletterRegistrationPage'),
   {
      fallback: <LoadingPage />,
   }
)

const LoadableNewsletterRegistrationPageV2 = loadable(
   () => import('../Pages/Newsletter/NewsletterRegistrationPageV2'),
   {
      fallback: <LoadingPage />,
   }
)

const LoadableNewsletterWelcomePage = loadable(
   () => import('../Pages/Newsletter/NewsletterWelcomePage'),
   {
      fallback: <LoadingPage />,
   }
)

const LoadableNewsletterUnregisterPage = loadable(
   () => import('../Pages/Newsletter/NewsletterUnregisterPage'),
   {
      fallback: <LoadingPage />,
   }
)

const LoadableActivateAccountPage = loadable(
   () => import('../Pages/Newsletter/ActivateAccountPage'),
   {
      fallback: <LoadingPage />,
   }
)

const LoadableBlogPage = loadable(() => import('../Pages/Blog/BlogLanding'), {
   fallback: <LoadingPage />,
})

const LoadableCategoryBlogPage = loadable(() => import('../Pages/Blog/BlogCategory'), {
   fallback: <LoadingPage />,
})

const LoadableBlogArticlePage = loadable(() => import('../Pages/Blog/BlogArticle'), {
   fallback: <LoadingPage />,
})

const LoadableStorePage = loadable(() => import('../Pages/Store/StoreCategory'), {
   fallback: <LoadingPage />,
})


const CategoryPage = ({ category, ...props }) => {
   const [getSetting, loadingSettings] = useSiteSettingsQuery()

   const setting = getSetting(null, HELLO_RETAIL_SETTINGS)
   const BEST_SELLER_CATEGORY = HelloRetailHelper(
      'BEST_SELLER_CATEGORY',
      setting
   )
   const LATEST_VISITED = HelloRetailHelper('LATEST_VISITED', setting)


   const parents = getParentOrCanonicalRoute(
      props?.result?.data?.route?.parents,
      category
   )

   const helloRetailHierarchies = getCategoryHierarchies(parents, category)

   const { helloRetailRecoms, helloRetailLoading } = UseHelloRetail(
      [BEST_SELLER_CATEGORY, LATEST_VISITED],
      helloRetailHierarchies
   )

   const { setBreadCrumbData } = useContext(SiteContext)

   const isHelloRetailAllowed = !HelloRetailConst.CATEGORY_FILTER.includes(
      category?.id
   )

   // let request = {
   //    boxes: {
   //       [BEST_SELLER_CATEGORY?.value]: {},
   //       [LATEST_VISITED?.value]: {},
   //    },
   //    pageLoaded: !loadingSettings,
   // }

   // const { addWishResponseBoxItems } = useAddwish(request)
   // console.log(addWishResponseBoxItems)

   const items = category?.data?.items

   // if (loadingSettings) return 'Loading...'
   // if (!category) return false

   /* Split custom elements (items) at readMore block
     and display above and below category grid content */
   const readMoreIndex = items?.findIndex(item => item.type === READ_MORE)
   const readMoreText =
      items?.[readMoreIndex]?.properties[0]?.value?.string || t('Läs mer...')

   let itemsAbove = items
   let itemsBelow = null
   let showReadMore = false

   // const brandCategoryId = parseInt(getSetting('dynamicCategoriesId'))
   const isBrandCategory = BRAND_PAGE_ID === category?.id
   const isSingleBrandCategory = category?.parent?.id == BRAND_PAGE_ID

   // const BLOG_PAGE_ID = 3820
   // const isBlogCategory = category?.id = BLOG_PAGE_ID
   // const isBlogSubCategory = category?.parent?.id == BLOG_PAGE_ID
   // category?.id == 3820 ||
   // category?.parent?.id == 3820 ||
   // category?.parent?.parent?.id == 3820

   if (items && readMoreIndex > 0) {
      itemsAbove = items.slice(0, readMoreIndex)
      itemsBelow = items.slice(readMoreIndex + 1, items.length)
      showReadMore = false
   }

   useEffect(() => {
      setBreadCrumbData({ parents, category })
   }, [category, parents, setBreadCrumbData])

   return (
      <>
         {/* <FindifyTracker /> */}
         <CartProvider query={cartQuery}>
            {_result => {
               return (
                  <>
                     {(() => {
                        if (isBrandCategory) {
                           return (
                              <LoadableBrandsPage
                                 category={category}
                                 parents={parents}
                              />
                           )
                        } else if (isSingleBrandCategory) {
                           return (
                              <LoadableBrandPage
                                 category={category}
                                 parents={parents}
                              />
                           )
                        } else if (category?.id == 4155 || category?.id == 4027) {
                           return (
                              <LoadableNewsletterRegistrationPageV2
                                 category={category}
                              />
                           )
                        } else if (category?.id == 4161) {
                           return (
                              <LoadableNewsletterWelcomePage
                                 category={category}
                              />
                           )
                        } else if (category?.id == 4028) {
                           return (
                              <LoadableNewsletterUnregisterPage category={category} />
                           )
                        } else if (category?.parent?.parent?.id == 3820 || category?.id == 4823 || category?.id == 4430 ) {
                           return (
                              <LoadableBlogArticlePage
                                 category={category}
                                 parents={parents}
                              />
                           )
                        } else if ( category?.parent?.id == 3820 ) {
                           return (
                              <LoadableCategoryBlogPage
                                 category={category}
                                 parents={parents}
                              />
                           )
                        } else if (category?.id == 3820) {
                           return (
                              <LoadableBlogPage
                                 category={category}
                                 parents={parents}
                              />
                           )
                        } else if (category?.id == 4373 || category?.parent?.id == 4373 ) {
                           // Borasbutiken
                           return (
                              <LoadableStorePage
                                 category={category}
                                 parents={parents}
                              />
                           )
                        } else if (category?.id == ACTIVATE_ACCOUNT_PAGE_ID) {
                           return (
                              <LoadableActivateAccountPage
                                 category={category}
                                 parents={parents}
                              />
                           )
                        } else {
                           return (
                              <LoadableCategoryStandardPage
                                 category={category}
                                 parents={parents}
                              />
                           )
                        }
                     })()}

                     {isHelloRetailAllowed && (
                        <HelloRetailWrapper>
                           <HelloRetailToJetshopCarousel
                              className="py-32"
                              products={getHelloRetailProducts(
                                 helloRetailRecoms,
                                 BEST_SELLER_CATEGORY
                              )}
                              loading={helloRetailLoading}
                              title={BEST_SELLER_CATEGORY?.title}
                              render={modifiedResults => <HelloRetailCarousel {...modifiedResults} />}
                           />

                           <HelloRetailToJetshopCarousel
                              className="py-32"
                              products={getHelloRetailProducts(
                                 helloRetailRecoms,
                                 LATEST_VISITED
                              )}
                              loading={helloRetailLoading}
                              title={LATEST_VISITED?.title}
                              render={modifiedResults => <HelloRetailCarousel {...modifiedResults} />}
                           />
                           
                           {/* <HelloRetailCarousel
                              className="py-32"
                              products={getHelloRetailProducts(
                                 helloRetailRecoms,
                                 BEST_SELLER_CATEGORY
                              )}
                              loading={helloRetailLoading}
                              title={BEST_SELLER_CATEGORY?.title}
                           ></HelloRetailCarousel> */}

                           {/* <HelloRetailCarousel
                              className="py-32"
                              products={getHelloRetailProducts(
                                 helloRetailRecoms,
                                 LATEST_VISITED
                              )}
                              loading={helloRetailLoading}
                              title={LATEST_VISITED?.title}
                           ></HelloRetailCarousel> */}

                        </HelloRetailWrapper>
                     )}
                  </>
               )
            }}
         </CartProvider>
      </>
   )
}

export default CategoryPage
